<template>
  <div>
    <div class="content">
      <p class="title">隐私政策声明</p>
      <p class="textone">为了更好地提供服务，我们按照合法、合理、安全、保密的原则，依据信息安全及保险法律法规要求，合理收集、管理和使用您的个人信息。若您未能提供相关信息和数据，可能会导致您无法在本平台 (含<span class="blod">App</span>、小程序)开立账户、使用网站功能或享受相关保险服务。</p>
      <p class="mastercopy">1.引言</p>
      <p class="textone">1.1.本隐私政策适用于南华保险经纪旗下各类平台(范围详见附录1：定义)所有产品和服务。</p>
      <p class="textone">1.2.本隐私政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这数据做些什么及如何保护这些数据。</p>
      <p class="textone">1.3.若您使用汇立天下平台的服务，即表示您认同我们在本政策中所述内容。在将您的信息用于本隐私权政策未涵盖的用途时，我们会事先征求您的同意。</p>
      <p class="textone">1.4.此外，我们在产品设计的过程中及最终呈现和用户体验环节，将尽可能地为您控制个人信息、保护个人隐私提供更为便捷的方式。例如：您可以在【搜索隐私设置】中方便地对捜索记录进行开关设置,如您关闭搜索记录，我们将不再継续记录您的搜索记录，这可能会导致您的搜索效率和搜素内容的个性化程度降低。您还可以通位【査阅/清理搜索框历史 展现词】实现在您的服务界面内查阅、清理您的搜索框历史词。 通过此功能，您可以査看您账户内的历史搜索词。</p>
      <p class="textone">1.5.本隐私政策将帮助您了解以下内容：</p>
      <p class="textone">（1）引言</p>
      <p class="textone">（2）我们收集的个人信息</p>
      <p class="textone">（3）我们如何使用您的个人信息</p>
      <p class="textone">（4）我们如何使用Cookie和同类技术</p>
      <p class="textone">（5）我们如何共享、 转让、公开披露您的个人信息</p>
      <p class="textone">（6）我们如何保存及保护您的个人信息</p>
      <p class="textone">（7）您的权利</p>
      <p class="textone">（8）我们如何处理未成年人的个人信息</p>
      <p class="textone">（9）您的个人信息如何跨境转移</p>
      <p class="textone">（10）本政策如何更新</p>
      <p class="textone">（11）如何联系我们</p>
      <p class="textone">感谢您对汇立天下平台的使用和信任！我们致力于维持您对我们的信任，悟守适用法律和我们対您的承诺，尽全力保证您的个人值息安全和合理使用。同时，我们郑重承诺，我们将按业界成熟的安全标准，釆取相应的安全保护措施来保护您的个人信息。</p>
      <p class="textone">请在使用我们的产品和/或服务前，仔细阅读并了解本隐私政策。</p>
      <p class="mastercopy">2. 我们收集的个人信息</p>
      <p class="textone">根据您与我们之间的关系，我们所收集的有关您的个人信息可能包括：</p>
      <p class="textone">2.1.—般信息与联系方式：姓名；地址；电子邮件地址和电话号码；性别；婚姻状况；家庭状况；出生日期；密码；教育背景；身体素质；照片；工作经历、技能与经验；专业执照与隶属机构；与投保人、被保险人之间的关系；以及发生保险事故的时间与原因。</p>
      <p class="textone">2.2.政府部门或机构颁发的识别号码：身份证号码；护照号码；社会保险号码；税务识别号码；军官证号码；或驾驶证或其它证件号码。</p>
      <p class="textone">2.3.财务信息和账户详情：银行账户号码和账户详情；信用记录和信用评分；资产；收入；以及其它支付和财务信息。</p>
      <p class="textone">2.4.疾痛状况和健康状况：目前或以前的身体、心理或疾病状况；健康状况；受伤或残疾情况；已进行过的手术；个人习惯(如抽烟或炊酒)；处方情况；以及病史。</p>
      <p class="textone">2.5.其它信息：在某些情况下，我们会收集有关您的家族病史、继承信息等其他信息。在预防、识别和调査欺诈过程中，我们可能获取有关您的犯罪记录或民事诉讼记录。我们还会获取您主动提供给我们的其他信息。</p>
      <p class="textone">2.6.电话录音、在线沟通及投保轨迹：您致电或在线联系我们保险咨询顾问、或者通过移动设备或电脑访问浏览我们公司网站页面，我们将会收集您的电话录音、在线沟通及投保轨迹记录。</p>
      <p class="textone">2.7.设备信息。为了提供更好的产品和服务并改善用户体验，我们会收集设备属性信息(例如IE的 硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件地址 MAC、广告标识符IDFA等)，设备连接信息(浏览器的类型、电信运营商、使用的语言)以及设备状态信息(例如设备传感器数据，设备应用安装列表)。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将您的设备信息或电话号码与您的南华帐户相关联。</p>
      <p class="textone">2.8.日志信息当您使用汇立天下平台产品和服务时，我们的服务器会自动记录一些信息，例如您对我们的产品或服务的使用情况、IP地址、所访问服务的 URL、浏览器的类型和使用的语言、下载、安装或使用移动应用和软件的信息、与通讯软件通讯的信息以及访问服务的日期、时间、时长等。</p>
      <p class="textone">2.9.位置信息当您使用具有定位功能的汇立天下平台产品和服务时，当您开启设着定位功能并使用我们基于位置提供的相关服务时，我们可能会收集和处理有关您实际所在位置的信息，以使得您不需要手动輸入自身地理坐标就可获得相关服务。我们会使用各种技术进行定位，这些技术包括IP地址、GPS以及能够提供相关信息的其他传感器(比如可能会为我们提供附 近设备、Wi-Fi 入点和基站的信息)。您可以通过关闭定位功能，停止对您的地理位置信息的收集，但您可能将无法获得相关服务成功能，或者无法达到相关IE务拟达到的效果。</p>
      <p class="textone">2.10.唯一应用程序编号某些产品和服务包含唯一应用程序编号，当您安装、激活、更新、 卸裁相关产品和服务或当这些产品和服务定期与南华通信(例如软件的更新)时，系统会将此编号以及与安装相关的值息(例如操作系统类型和应用程序版本号)发送给南华。</p>
      <p class="mastercopy">3.我们如何便用个人億息</p>
      <p class="textone">3.1.我们将以如下目的和方式使用您的个人信息。</p>
      <p class="textone">3.2.在我们业务过程中与您和其他相关人士进行沟通。</p>
      <p class="textone">3.3.向您寄送我们的保单、合同以及其他相关的重要信息。</p>
      <p class="textone">3.4.改善服务品质(例如，致电我们的联系电话时，对来电进行录音或监测)；进行市场研究和分析 (包括满意度调査)。为了让您有更好的体验，改善我们的服务或经您同意的其他用途，在符合相关法律法規的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。例如，将您在使用我们某项服务时的信息，用于另一项服务中向您展示个性化的 内容或广吿、用于用户研究分析与统计等服务。</p>
      <p class="textone">3.5.根据您所表明的营销偏好，向您提供营销信息(包括与所选第三方合作伙伴提供的其他产品和服务有关的信息)。通过使用收集的信息，我们会得以向您提供定制内容，例加向您展现或推荐相关程度更高(而非普遍推送)的搜索结果、信息流或者广吿/推广信息结果。</p>
      <p class="textone">3.6.便利您参与竞赛、抽奖和类似促销活动以及管理这些活动。有些活动有附加条款和条件，其中可能包括我们如何使用和披露您的个人信息，因优，我们建议您仔细阅读这些条款和条件。</p>
      <p class="textone">3.7.用于业务运营管理；各类客户服务；财务会计、收付款项等；IT系统、数据和网站存储、业务备份等；审计计、检査等。我们使用收集的信息来提供并改进我们及南华关联方、合作方提供的产品和服务， 并进行必要的业务运营，例如运营产品或提供服务， 评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。为改进我们及南华关联方、合作方的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，南华可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的统计类产品。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识別内容的大数据分析信息。</p>
      <p class="textone">3.8.解决投诉，处理数据访问或资料更正请求。</p>
      <p class="textone">3.9.为您提供安全保障。为提高您使用我们与南华关联方、合作方提供的产品和服务的安全性，我们可能使用您的信息用于身份验证、客户服务、安全防范，诈骗监测、信贷分析等，以预防、发现、调査欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们的其他用户、我们或南华关联方、合作方及社会公众的合法权益。</p>
      <p class="textone">3.10.遵守适用的法律法规和监管要求(包括您的居住国法律以外的法律)，例如与反洗钱和反恐融资有关的法律法规和监管要求；遵守法律程序以及其他的政府机构要求。</p>
      <p class="textone">3.11.当我们要将信息用于本隐私政策未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先证求您的同意。</p>
      <p class="mastercopy">4.我们如何使用cookie和同类技术</p>
      <p class="textone">4.1.为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。 Cookie通常包含标识符、站点名称以及一些号码ID字 符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie 技术，我们能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。</p>
      <p class="textone">4.2.当您使用南华的产品和服务时，会向您的设备发送Cookie。当您与我们提供给合作伙伴的服务 (例如广吿和/或推广服务，以及可能显示在其他网站上的由南华提供的服务功能)进行交互时，您必须允许我们将Cookie (或者其他匿名标识符)发送给南华的服务器。</p>
      <p class="textone">4.3.我们不会将Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。 您可以清除计算机上保存的所有Cookie,大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，則无法登录或使用依赖于Cookie的南华提供的服务或功能。</p>
      <p class="textone">4.4.您可以通过更改您的浏览器设置限制南华保险经纪公司对Cookie的使用。以IE浏览界为例，您可以在IE浏览器右上方的下拉菜单的“Internet选项”的“隐私选项卡”中，通过“设置-高级”，选择阻止我们访问您的cookie。</p>
      <p class="mastercopy">5.我们如何共享、转让、公开披露您的个人信息 </p>
      <p class="textone">5.1.共享</p>
      <p class="textone">我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除南华及其关联公司外的任何公司、组织和个人分享您的信息：</p>
      <p class="textone">5.1.1.在获得您的明确同意后，我们会与其他方共享您的个人信息。我们可能会根据法律法规规定，或按政府主管部门的强制性要求或司法裁定，对外共享您的个人信息。</p>
      <p class="textone">5.1.2.仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，您在投保时，我们需要与保险公司共享您的个人信息才能安排其为您提供后续服务。我们仅会出于合法、正当、必要、 特定、明确的目的共享您的个人信息，并且只会共享与提供服务相关的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</p>
      <p class="textone">目前，我们的授权合作伙伴包括以下类型:</p>
      <p class="textone">（1）服务平台或服务提供商。南华各产品接入了丰富的第三方服务。当您选择使用该第三方服务时，您授权我们将该信息提供给第三方服务平台或服务提供商，以便其基于相关信息为您提供服务。</p>
      <p class="textone">（2）软硬件/系统服务提供商。当第三方软硬件/系统产品或服务与南华的产品或服务结合为您提供服务时，经您授权，我们会向第三方软硬件/系统服务提供商提供您必要的个人信息，以便您使用服务，或用于我们分析产品和服务使用情况，来提升您的使用体验。</p>
      <p class="textone">(3)广告、咨询类服务商/广告主。未经您授权， 我们不会将您的个人身份信息(指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份)与提供广告、咨询类服务商共享。但我们可能会将经处理无法识别您的身份且接收方无法复原的信息，例如经匿名化处理的用户画像，与广吿或咨询类服务商或广吿主共享，以帮助其在不识别您个人的前提下，提升广吿有效触达率，以及分析我们的产品和服务使用情况等。</p>
      <p class="textone">5.1.3.为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息、保护南华关联公司或其他用户或公众的人身财产安全免遭侵害或与您取得联系，您的个人信息可能会与我们的关联公司共享。 我们只会共享必要的个人信息(如为便于您使用南华账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息)，如果我们改变个人信 息的使用及处理目的，将再次征求您的授权同意。</p>
      <p class="textone">5.1.4.对我们与之共享个人信息的公司、組织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
      <p class="textone">5.2.公开披露</p>
      <p class="textone">我们仅会在以下情形下，公开披露您的个人信息:</p>
      <p class="textone">（1）获得您的明确同意；</p>
      <p class="textone">（2）基于法律的披露:在法律法规、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开您的个人信息。</p>
      <p class="textone">共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
      <p class="textone">在以下情形中,共享、转让、公开披露您的个人信息无事先征得您的授权同意：</p>
      <p class="textone">（1）与国家安全、国防安全直接相关的；</p>
      <p class="textone">（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p class="textone">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p class="textone">（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p class="textone">（5）您自行向社会公众公开的个人信息；</p>
      <p class="textone">（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p class="textone">（7）根据个人信息主体要求签订和履行合同所必需的；</p>
      <p class="textone">（8）用于维护所提供的产品成服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
      <p class="textone">（9）法律法规规定的其他情形。</p>
      <p class="textone">报据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
      <p class="mastercopy">6.我们如何保存和保护您的个人值息 </p>
      <p class="mastercopy">6.1.保存期限</p>
      <p class="textone">您在使用南华产品及服务期间，我们将持续为您保存您的个人信息。如果您注册帐户或主动删除上述信息，我们将依据网络安全法等法律法規规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用。</p>
      <p class="mastercopy">6.2.保存地域</p>
      <p class="textone">您的个人信息均储存于中华人民共和国境内。</p>
      <p class="mastercopy">6.3.安全措施</p>
      <p class="textone">6.3.1我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问 、公开披露、使用、修改、损坏或丢失。我们会釆取一切合理可行的措施，保护您的个人信息。例如， 在您的浏览器与“服务”之间交换数据(如银行卡信息)时受SSL加密保护；我们同时对南华旗下网站提供https安全浏览方式；我们会使用加密技术确保数 据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
      <p class="textone">6.3.2我们会釆取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
      <p class="textone">6.3.3互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密， 我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>
      <p class="textone">6.3.4.互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、成管理防护设施遇到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
      <p class="textone">6.3.5.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议对您的补救措施等。我们将及时将事件相关情况以部件、信函、电话、 推送通知等方式告知您，难以逐一吿知个人信息主体时，我们会釆取合理、有效的方式发布公吿。</p>
      <p class="textone">同时，我们还将按照监管部门要求，主动上报个人倍息安全事件的处置情况。</p>
      <p class="mastercopy">7.您的权利</p>
      <p class="textone">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
      <p class="mastercopy">7.1.访问您的个人信息</p>
      <p class="textone">您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</p>
      <p class="textone">账户信息一如果您希望访问或编辑您的账户中的个人基本责任信息、联系方式信息、更改您的密码、 添加安全信息、进行账户关联、身份认证等，您可以访问【账户安全】执行此类操作。</p>
      <p class="textone">搜索信息——您可以在【搜索隐私设置】中访问或清楚您的搜索历史记录、査看和修改兴趣以及管理其他数据。</p>
      <p class="mastercopy">7.2.更正您的个人信息</p>
      <p class="textone">您发现我们处理的关于您的个人信息有错误时， 您有权对错误或不完整的信息作出更正或更新，您可以通过我们提供的联系方式随时与我们联系，提出更正申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。</p>
      <p class="mastercopy">7.3.删除您的个人信息</p>
      <p class="textone">在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过我们提供的联系方式反馈随时与我们联系：</p>
      <p class="textone">（1）如果我们违法法律法规或与您的约定收集、使用、与他人共事或转让您的个人信息。</p>
      <p class="textone">（2）如果我们违反法律法规规定而与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</p>
      <p class="textone">（3）当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息.但会在备份更新时删除这些信息。</p>
      <p class="mastercopy">7.4.改变您授权同意的范围</p>
      <p class="textone">每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</p>
      <p class="textone">如您想改变授权范围，您可通过汇立天下平台产品的隐私设置修改授权范围，例如：</p>
      <p class="textone">（1）您可以登录【账号安全】界面解除汇立天下账号与第三方帐号的绑定关系，可以在【搜索隐私设置】中，方便地对搜索记录进行开关设置。</p>
      <p class="textone">（2）当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此您的同意就授权而开展的个人信息处理。</p>
      <p class="mastercopy">7.5.注销您的账户</p>
      <p class="textone">您随时可注销此前注册的账户。您可以登录【账户安全】 ，注销您的南华帐号。一旦您注销南华账号，将无法使用南华全域用户产品和服务，因此请您谨慎操作。我们为了保护您或他人的合法权益会结合您对南华各产品和服务的使用情况判断是否支持您的注销请求。</p>
      <p class="mastercopy">7.6.提前获知产品和服务停止运营</p>
      <p class="textone">南华愿一直陪伴您，若因特殊原因导致汇立天下平台产品和服务被迫停止运营，我们将按照法律法规要求在产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。</p>
      <p class="mastercopy">7.7.响应您的上述需求</p>
      <p class="textone">为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
      <p class="textone">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改変现行惯例)、给他人合法权益帯来风险或者非常不切实际 (例如，涉及备份磁帯上存放的信息)的请求，我们可能会予以拒绝。</p>
      <p class="textone">在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</p>
      <p class="textone">（1）与国家安全、国防安全直接相关的；</p>
      <p class="textone">（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p class="textone">（3）与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
      <p class="textone">（4）我们有充分证据表明您存在主观恶意或滥用权利的(如您的情求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围)；</p>
      <p class="textone">（5）响应个人信息主体的请求将导致您或其他个人、组织的合法枳益受到严重损害的；</p>
      <p class="textone">（6）涉及商业秘密的。</p>
      <p class="mastercopy">8.我们如何处理未成年人的个人信息</p>
      <p class="textone">我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。</p>
      <p class="textone">对于经父母或监护人同意而收集的未成年人个人信息情况，我们只会在收到法律允许、父母或监护人明确冋意或者保护儿童所必要的情况下使用或公开披露此信息。</p>
      <p class="textone">如果父母或监护人发现我们在未获其同意的情况下收集了未成年人的个人值息，请通过我们提供的联系方式联系我们，我们会设法尽快删除相关数据。</p>
      <p class="mastercopy">9.本政策如何更新</p>
      <p class="textone">我们可能适时修订奉政策内容。如该等変更会导致您在本政策项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示、向您发送电子部件等方式通知您。我们会在本页面上发布购本隐私政策所做的任何变更。</p>
      <p class="textone">若您不同意该等变更可以停止使用汇立天下平台产品和服务,若您继续使用我们的产品或服务，即表示您同意受修订后的本隐私政策的约束。</p>
      <p class="textone">本隐私政策所指的员大变更包括但不限于：</p>
      <p class="textone">（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</p>
      <p class="textone">（2）个人信息共享、转让或公开披露的主要对象发生変化；</p>
      <p class="textone">（3）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p class="textone">（4）其他可能对您的个人信息权益产生重大影响的变化时。</p>
      <p class="mastercopy">10.如何联系我们</p>
      <p class="textone">客服邮箱：hckg@hcpaia.com</p>
      <p class="textone">客服微信：KFHLTX001</p>
      <p class="textone">联系电话：0755-82237293</p>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped>
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.3rem 0.5rem 1.5rem 0.5rem;
  background-color: #ffffff;
}
.title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.textone {
  text-align: justify;
  text-indent: 0.5rem;
  line-height: 2;
}
.mastercopy {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.blod{
  font-weight: bold;
}
</style>
